// $( document ).on('turbolinks:load', function() {
    jQuery(document).ready(function($) {
        "use strict";
        $('#hamburger_menu').click(function (){
            $('#navigation_panel').slideToggle("slow");
            $('#closer').toggle();
            $('#hamburger').toggle();
            // $('#logo').toggleClass('fixed');
        });

        $('.shopify-buy-frame').ready().find('iframe').each( function(e){
            console.log(e.target());
            e.target().prop('title', 'Acquista biglietto visita in cantina Lago di Garda');
        });

        $('#footer_social a').attr('rel', 'noopener noreferrer nofollow');
        $('.shopify-buy-frame iframe').each( function (e){
            $(e.target).attr('title', 'acquista biglietto visita in cantina Lago di Garda');
            console.log( $(e.target) );
        });

        jQuery(document).find('.shopify-buy-frame').find('iframe').attr('title', 'acquista biglietto visita in cantina Lago di Garda');

        $('#single_pack .pack_order').click( function (){
            let pack_id = ($(this).parent().parent().parent().index()) + 1;
            $('#groups_form_experience option').eq(pack_id).prop('selected', true);
        });
    });
// });